


































import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_ONBOARDING_LOADING } from "@/store/modules/onboarding/constants";
import { FormFieldRulesStringArr } from "@/interfaces/v_form";
import { AvailabilityOptions } from "@/interfaces/onboarding/onboarding";

export default Vue.extend({
  name: "CandidateAvailability",
  data() {
    return {
      form: false as boolean,
      availability_rules: [
        (value: string[]) =>
          value.length > 0 || this.$t("candidate-onboarding.availability-error")
      ] as FormFieldRulesStringArr[],
      availability: [] as string[],
      items: [
        {
          text: this.$t("candidate-onboarding.availability-op1"),
          value: "online"
        },
        {
          text: this.$t("candidate-onboarding.availability-op2"),
          value: "on site"
        }
      ] as AvailabilityOptions[]
    };
  },
  computed: {
    ...mapGetters("onboarding", {
      get_onboarding_loading: GET_ONBOARDING_LOADING
    })
  },
  methods: {
    submit_form() {
      this.$emit("update_stepper", {
        step: 3,
        value: this.availability
      });
    }
  }
});
