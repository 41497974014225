





















































































import Vue from "vue";
import {
  GET_CANDIDATE_DATA,
  ONBOARD_CANDIDATE,
  RESET_CANDIDATE_DATA,
  SET_CANDIDATE_DATA
} from "@/store/modules/onboarding/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  CandidateData,
  SetCandidateDataPayload,
  SocialLinks
} from "@/store/modules/onboarding/interfaces";
import CandidateAvatar from "@/views/onboarding/candidate/CandidateAvatar.vue";
import CandidateResume from "@/views/onboarding/candidate/CandidateResume.vue";
import CandidateSocialLinks from "@/views/onboarding/candidate/CandidateSocialLinks.vue";
import CandidateAvailability from "@/views/onboarding/candidate/CandidateAvailability.vue";
import { USER_STATE } from "@/store/modules/auth/constants";
import { UserState } from "@/store/modules/auth/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "CandidateIndex",
  components: {
    CandidateAvailability,
    CandidateSocialLinks,
    CandidateResume,
    CandidateAvatar
  },
  data() {
    return {
      candidate_stepper: 1 as number,
      back_icon: require("@/assets/icons/linear/arrow-left-1.svg"),
      display_back_btn: true as boolean,
      disable_next_btn: false as boolean
    };
  },
  watch: {
    candidate_stepper(new_value) {
      this.display_back_btn = new_value < 5;
    }
  },
  computed: {
    ...mapGetters("onboarding", {
      get_candidate_data: GET_CANDIDATE_DATA
    })
  },
  methods: {
    ...mapMutations("auth", {
      update_user_state: USER_STATE
    }),
    ...mapMutations("onboarding", {
      set_candidate_data: SET_CANDIDATE_DATA,
      reset_candidate_data: RESET_CANDIDATE_DATA
    }),
    ...mapActions("onboarding", {
      onboard_candidate: ONBOARD_CANDIDATE
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    async go_back_handle() {
      if (this.candidate_stepper === 1) this.$router.back();
      else this.candidate_stepper -= 1;
    },
    /**
     * Function to update stepper & update values in store
     * @param step: number => Current step number => required for updating value in store
     * @param value => value to update in store
     */
    async update_stepper({
      step,
      value
    }: {
      step: number;
      value: SocialLinks[] | File | string[];
    }) {
      // For candidate avatar
      if (step === 1) {
        const payload: SetCandidateDataPayload = {
          obj_name: "candidate_avatar",
          value: value
        };
        this.set_candidate_data(payload);
      }
      // For candidate cv
      else if (step === 2) {
        const payload: SetCandidateDataPayload = {
          obj_name: "candidate_resume",
          value: value
        };
        this.set_candidate_data(payload);
      }
      // For candidate availability
      else if (step === 3) {
        const payload: SetCandidateDataPayload = {
          obj_name: "candidate_availability",
          value: value
        };
        this.set_candidate_data(payload);
      }
      // For candidate social links
      else if (step === 4) {
        const payload: SetCandidateDataPayload = {
          obj_name: "candidate_social_links",
          value: value
        };
        this.set_candidate_data(payload);
        // Complete candidate onboarding
        await this.complete_candidate_onboarding();
      }
      this.candidate_stepper += 1;
    },
    /**
     * Function to add candidate data in backend
     */
    async complete_candidate_onboarding() {
      const obj: CandidateData = this.get_candidate_data;
      // Validate candidate data
      const valid_data = this.validate_data(obj);
      if (valid_data) {
        const payload: FormData = new FormData();
        payload.append("candidate_avatar", obj.candidate_avatar as never);
        payload.append("candidate_resume", obj.candidate_resume as never);
        payload.append(
          "candidate_social_links",
          JSON.stringify(obj.candidate_social_links)
        );
        payload.append(
          "candidate_availability",
          JSON.stringify(obj.candidate_availability)
        );
        // Call store function => for candidate onboarding
        const response = await this.onboard_candidate(payload);
        // If candidate onboarding successfully => navigate to onboard success page
        if (response) {
          this.update_user_state(UserState.ONBOARDING_COMPLETED);
          await this.$router.push({
            name: "onboarding-success"
          });
          this.reset_candidate_data();
        }
        // If candidate onboarding failed => reset stepper
        else {
          this.candidate_stepper = 0;
        }
      }
    },
    /**
     * Validate candidate data
     * @param data{CandidateData}
     * @return boolean
     */
    validate_data(data: CandidateData): boolean {
      if (!data.candidate_avatar) {
        this.set_root_error("Company avatar is missing");
        this.candidate_stepper = 1;
        return false;
      } else if (!data.candidate_resume) {
        this.set_root_error("Company resume is missing");
        this.candidate_stepper = 2;
        return false;
      } else return true;
    }
  }
});
